import React, {useImperativeHandle, useRef} from 'react'
import {useTranslation} from 'next-i18next'
import WizButton from '@component/buttons/WizButton'
import useSnackbar from '@hook/useSnackbar'
import Text from '@component/Text/Text'
import {apiFollow} from '@api/user/profile/follow'
import {apiUnfollow} from '@api/user/profile/unfollow'
import WizContentModal from '@component/modal/WizContentModal'
import {createLog} from '@util/logs'

interface IProps {
    user_id: string
    isFollowing?: boolean
    refreshCallback?: () => void
}

const FollowModal = ({user_id, isFollowing = false, refreshCallback}: IProps, ref: any) => {
    const modalRef = useRef<any>()
    const {t} = useTranslation()

    const {showErrorSnackbar} = useSnackbar()

    useImperativeHandle(ref, () => ({
        show: () => {
            modalRef.current.show()
        },
    }))

    if (isFollowing === false) {
        return (
            <WizContentModal size={'600'} title={t('modal.follow.title')} ref={modalRef}>
                <Text className={'text-body2 text-gray02 dark:text-dark_gray02'}>{t('modal.follow.desc')}</Text>

                <div className={'grid grid-cols-2 gap-[5px] mt-[20px]'}>
                    <WizButton
                        text={t('modal.unfollow.cancel')}
                        buttonType={'border'}
                        onClick={() => modalRef.current.close()}
                    />
                    <WizButton
                        text={t('modal.unfollow.confirm')}
                        buttonType={'primary'}
                        onClick={async () => {
                            modalRef.current.close()
                            const {data, error} = await apiFollow(user_id)
                            if (!data) {
                                showErrorSnackbar(error)
                                createLog('error', 'Follow_User_Error')
                            } else {
                                createLog('event', 'Follow_User_Success', {follow_user_id: user_id})
                            }
                            refreshCallback && refreshCallback()
                        }}
                    />
                </div>
            </WizContentModal>
        )
    } else {
        return (
            <WizContentModal size={'600'} title={t('modal.unfollow.title')} ref={modalRef}>
                <Text className={'text-body2 text-gray02 dark:text-dark_gray02'}>{t('modal.unfollow.desc')}</Text>

                <div className={'grid grid-cols-2 gap-[5px] mt-[20px]'}>
                    <WizButton
                        text={t('modal.unfollow.cancel')}
                        buttonType={'border'}
                        onClick={() => modalRef.current.close()}
                    />
                    <WizButton
                        text={t('modal.unfollow.confirm')}
                        buttonType={'red'}
                        onClick={async () => {
                            modalRef.current.close()
                            const {data, error} = await apiUnfollow(user_id)
                            if (!data) {
                                showErrorSnackbar(error)
                                createLog('error', 'Unfollow_User_Error')
                            } else {
                                createLog('event', 'Unfollow_User_Success', {unfollow_user_id: user_id})
                            }
                            refreshCallback && refreshCallback()
                        }}
                    />
                </div>
            </WizContentModal>
        )
    }
}

export default React.forwardRef(FollowModal)

import React, {useImperativeHandle, useRef, useState} from 'react'
import {useTranslation} from 'next-i18next'
import Text from '@component/Text/Text'
import useFormatDate from '@hook/useFormatDate'
import {getNumberColorClass} from '@util/etc'
import useFormatNumber from '@hook/useFormatNumber'
import ExchangeIcon from '@component/images/ExchangeIcon'
import {ICopyHistory} from '@api/copy/copier/history/getCopyHistory'
import WizContentModal from '@component/modal/WizContentModal'
import useQueryGetExchanges from '@hook/query/core/exchange/useQueryGetExchanges'
import useQueryGetPendingInvoices from '@hook/query/user/account/invoices/useQueryGetPendingInvoices'
import useQueryFetchMe from '@hook/query/account/useQueryFetchMe'
import IconNext from '@svg/common/ic_next.svg'

const CopyHistoryDetailModal = ({}, ref: any) => {
    const modalRef = useRef<any>()
    const {t} = useTranslation()
    const {getExchange} = useQueryGetExchanges()
    const {data: me} = useQueryFetchMe()
    const [historyData, setHistoryData] = useState<ICopyHistory>()
    const {displayDateFromText} = useFormatDate()
    const {displayPercent, displayBalance} = useFormatNumber()
    const {data: pendingInvoices} = useQueryGetPendingInvoices()

    useImperativeHandle(ref, () => ({
        show: item => {
            setHistoryData(item)
            modalRef.current.show()
        },
        close: () => {
            modalRef.current.close()
        },
    }))

    return (
        <WizContentModal ref={modalRef} title={t('modal.copyHistory.title')} size={'800'}>
            <div
                className={
                    'mt-[30px] border border-blue_tint01 dark:border-dark_blue_tint01 bg-blue_tint02 dark:bg-dark_blue_tint02 py-[20px] px-[20px] sm:px-[10px] rounded-[5px]'
                }>
                <div className={'flex w-full justify-between gap-x-[10px]'}>
                    <div className={'text-center flex-1'}>
                        <div
                            className={
                                'border-[2px] border-gray08 dark:border-dark_gray08 w-[50px] h-[50px] bg-white dark:bg-dark_gray09 mx-auto rounded-full flex items-center justify-center'
                            }>
                            <ExchangeIcon src={getExchange(historyData?.exchange)?.icon} size={30} />
                        </div>
                        <div className={'pt-[5px] items-center space-x-[5px] '}>
                            <Text className={'text-ti2 text-gray02 dark:text-dark_gray02'}>Me</Text>
                            <Text
                                className={
                                    'text-h3 sm:text-ti1 text-center text-gray01 dark:text-dark_gray01 break-all'
                                }
                                enablePreWhiteSpace={false}>
                                {historyData?.copier_name || me?.name}
                            </Text>
                        </div>
                    </div>
                    <div className={'text-center content-center w-[80px] sm:w-[40px]'}>
                        <IconNext className={'w-[30px] fill-blue dark:fill-dark_blue mx-auto mb-[5px]'} />
                        <Text className={'text-ti2 text-blue dark:text-dark_blue'}>Copy</Text>
                    </div>
                    <div className={'text-center flex-1'}>
                        <div
                            className={
                                'border-[2px] border-gray08 dark:border-dark_gray08 w-[50px] h-[50px] bg-white dark:bg-dark_gray09 mx-auto rounded-full flex items-center justify-center'
                            }>
                            <ExchangeIcon src={getExchange(historyData?.exchange)?.icon} size={30} />
                        </div>
                        <div className={'pt-[5px] items-center space-x-[5px] '}>
                            <Text className={'text-ti2 text-gray02 dark:text-dark_gray02'}>Leader</Text>
                            <Text
                                className={
                                    'text-h3 sm:text-ti1 text-center text-gray01 dark:text-dark_gray01 break-all'
                                }
                                enablePreWhiteSpace={false}>
                                {historyData?.leader_name ? historyData?.leader_name : historyData?.name}
                            </Text>
                        </div>
                    </div>
                </div>
            </div>
            <div className={'mt-[18px] pb-[10px]'}>
                <Text className={'text-ti1 text-gray01 dark:text-dark_gray01'}>{t('modal.copyHistory.asset')}</Text>
                <div className={'flex flex-col px-[10px] pt-[10px] space-y-[15px] sm:px-0'}>
                    <div className={'flex justify-between items-center'}>
                        <Text className={'text-body2 text-gray02 dark:text-dark_gray02'}>
                            {t('modal.copyHistory.period')}
                        </Text>
                        <Text className={'text-ti2 text-gray01 dark:text-dark_gray01'}>
                            {t('copyHistory.table.column.period.content', {
                                start_date: displayDateFromText(historyData?.start_at, t('dateFormat.date')),
                                end_date: displayDateFromText(historyData?.deleted_at, t('dateFormat.date')),
                            })}
                        </Text>
                    </div>
                    <div className={'flex justify-between items-center'}>
                        <Text className={'text-body2 text-gray02 dark:text-dark_gray02'}>
                            {t('modal.copyHistory.initBalance')}
                        </Text>
                        <Text className={'text-ti2 text-gray01 dark:text-dark_gray01'}>
                            {historyData?.initial_balance
                                ? displayBalance(historyData?.initial_balance, {showPreSign: true})
                                : displayBalance(0, {showPreSign: true})}
                        </Text>
                    </div>
                    <div className={'flex justify-between items-center'}>
                        <Text className={'text-body2 text-gray02 dark:text-dark_gray02'}>
                            {t('modal.copyHistory.deposit')}
                        </Text>
                        <Text className={'text-ti2 text-gray01 dark:text-dark_gray01'}>
                            {historyData?.deposit
                                ? displayBalance(historyData?.deposit, {showPreSign: true})
                                : displayBalance(0, {showPreSign: true})}
                        </Text>
                    </div>
                    <div className={'flex justify-between items-center'}>
                        <Text className={'text-body2 text-gray02 dark:text-dark_gray02'}>
                            {t('modal.copyHistory.withdraw')}
                        </Text>
                        <Text className={'text-ti2 text-gray01 dark:text-dark_gray01'}>
                            {historyData?.withdraw
                                ? displayBalance(historyData?.withdraw, {showPreSign: true})
                                : displayBalance(0, {showPreSign: true})}
                        </Text>
                    </div>
                    <div className={'flex justify-between items-center'}>
                        <Text className={'text-body2 text-gray02 dark:text-dark_gray02'}>
                            {t('modal.copyHistory.profit')}
                        </Text>
                        <Text className={`text-ti2 ${getNumberColorClass(historyData?.earning)} text-right`}>
                            {displayBalance(historyData?.earning, {showPreSign: true})} (
                            {displayPercent(historyData?.profit)})
                        </Text>
                    </div>

                    <div className={'border-b border-blue_tint01 dark:border-dark_blue_tint01'} />
                    <div className={'flex justify-between items-center'}>
                        <Text className={'text-body2 text-gray02 dark:text-dark_gray02'}>
                            {t('modal.copyHistory.currentBalance')}
                        </Text>
                        <Text className={'text-ti2 text-gray01 dark:text-dark_gray01'}>
                            {displayBalance(historyData?.balance, {showPreSign: true})}
                        </Text>
                    </div>
                </div>
            </div>
        </WizContentModal>
    )
}

export default React.forwardRef(CopyHistoryDetailModal)

import React, {useImperativeHandle, useRef, useState} from 'react'
import {useTranslation} from 'next-i18next'
import WizButton from '@component/buttons/WizButton'
import useSnackbar from '@hook/useSnackbar'
import Text from '@component/Text/Text'
import {apiCancelOpenOrder} from '@api/key/portfolio/cancelOpenOrder'
import WizContentModal from '@component/modal/WizContentModal'
import {createLog} from '@util/logs'

interface IProps {
    refreshCallback?: () => void
}

const CancelOpenOrderModal = ({refreshCallback}: IProps, ref: any) => {
    const modalRef = useRef<any>()
    const {t} = useTranslation()
    const [keyId, setKeyId] = useState('')
    const [orderId, setOrderId] = useState('')
    const {showErrorSnackbar} = useSnackbar()

    useImperativeHandle(ref, () => ({
        show: (key_id: string, order_id: string) => {
            setKeyId(key_id)
            setOrderId(order_id)
            modalRef.current.show()
        },
        close: () => {
            modalRef.current.close()
        },
    }))

    const cancelOrder = async () => {
        createLog('event', 'open_order_modal_cancel_open_order', {
            key_id: keyId,
            order_id: orderId,
        })
        const {data, error, status} = await apiCancelOpenOrder(keyId, orderId)
        if (!data) {
            createLog('error', 'open_order_modal_cancel_open_order_failed', {
                key_id: keyId,
                order_id: orderId,
            })
            showErrorSnackbar(error)
        }
    }

    return (
        <WizContentModal size={'600'} title={t('modal.cancelOpenOrder.title')} ref={modalRef}>
            <Text className={'text-body2 text-gray02 dark:text-dark_gray02'}>{t('modal.cancelOpenOrder.desc')}</Text>

            <div className={'grid grid-cols-2 gap-[5px] mt-[20px]'}>
                <WizButton
                    text={t('modal.cancelOpenOrder.cancel')}
                    buttonType={'border'}
                    onClick={() => modalRef.current.close()}
                />
                <WizButton
                    text={t('modal.cancelOpenOrder.confirm')}
                    buttonType={'red'}
                    onClick={async () => {
                        modalRef.current.close()
                        await cancelOrder()
                        createLog('event', 'open_order_modal_cancel_open_order', {
                            key_id: keyId,
                            order_id: orderId,
                        })
                        refreshCallback && refreshCallback()
                    }}
                />
            </div>
        </WizContentModal>
    )
}

export default React.forwardRef(CancelOpenOrderModal)

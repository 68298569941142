import React, {useImperativeHandle, useRef, useState} from 'react'
import {useTranslation} from 'next-i18next'
import WizButton from '@component/buttons/WizButton'
import WizInputText from '@component/inputs/WizInputText'
import CurrencySelect from '@component/select/CurrencySelect'
import {ICurrency} from '@type/currency'
import {useCurrencyStore} from '@store/CurrencyStore'
import useFormatNumber from '@hook/useFormatNumber'
import useSnackbar from '@hook/useSnackbar'
import WizContentModal from '@component/modal/WizContentModal'
import {createLog} from '@util/logs'
import {shallow} from 'zustand/shallow'
import {apiEditEntryPrice} from '@api/key/portfolio/editAvgEntryPrice'
import useDidMountEffect from '@hook/UseDidMountEffect'

interface IProps {
    refreshCallback?: () => void
}

const EditAvgBuyPriceModal = ({refreshCallback}: IProps, ref: any) => {
    const modalRef = useRef<any>()
    const {t} = useTranslation()
    const [keyId, setKeyId] = useState('')
    const [symbol, setSymbol] = useState('')
    const [price, setPrice] = useState('')
    const [defaultPrice, setDefaultPrice] = useState(0)
    const [currency, setCurrency] = useState<ICurrency>()
    const {getSelectedCurrency, changeCurrency, selectedSymbol} = useCurrencyStore(
        state => ({
            selectedSymbol: state.selectedSymbol,
            getSelectedCurrency: state.getSelectedCurrency,
            changeCurrency: state.changeCurrency,
        }),
        shallow,
    )
    const {displayPrice} = useFormatNumber()
    const {showSnackbar, showErrorSnackbar} = useSnackbar()

    useImperativeHandle(ref, () => ({
        show: (keyId: string, symbol: string, defaultPrice: number) => {
            setKeyId(keyId)
            setSymbol(symbol)
            setPrice(displayPrice(defaultPrice, {currencySymbol: getSelectedCurrency()?.symbol}))
            setDefaultPrice(defaultPrice)
            setCurrency(getSelectedCurrency())
            modalRef.current.show()
        },
        close: () => {
            modalRef.current.close()
        },
    }))

    const apiEditAvgEntryPrice = async () => {
        const usdPrice = Number(price) * currency?.value
        
        createLog('event', 'asset_table_edit_avg_entry_price', {
            key_id: keyId,
            symbol: symbol,
            avg_entry_price: usdPrice?.toString(),
        })

        const {data, error, status} = await apiEditEntryPrice(keyId, symbol, usdPrice?.toString())

        if (data?.success) {
            showSnackbar(t('modal.changeAvgBuyPrice.submit.res.success'))
        } else {
            createLog('error', 'asset_table_edit_avg_entry_price_failed', {
                key_id: keyId,
                symbol: symbol,
                avg_entry_price: usdPrice?.toString(),
            })
            showErrorSnackbar(error)
        }
    }

    const onChangeCurrency = item => {
        changeCurrency(item?.id as string)
        setCurrency(item?.value)
    }

    useDidMountEffect(() => {
        setPrice(displayPrice(Number(defaultPrice), {currencySymbol: currency?.symbol?.toLowerCase()}))
    }, [currency, defaultPrice])

    return (
        <WizContentModal
            size={'600'}
            title={t('modal.changeAvgBuyPrice.title')}
            enabledOverFlowY={false}
            ref={modalRef}>
            <WizInputText
                className={'mt-[10px]'}
                type={'number'}
                onChanged={setPrice}
                text={price?.toString()}
                defaultDescription={t('modal.changeAvgBuyPrice.desc')}
                renderRightArea={
                    <div className={'flex items-center'}>
                        <CurrencySelect onChanged={e => onChangeCurrency(e)} selectedSymbol={selectedSymbol} />
                    </div>
                }
            />

            <div className={'grid grid-cols-2 gap-[5px] mt-[20px]'}>
                <WizButton
                    text={t('modal.changeAvgBuyPrice.cancel')}
                    buttonType={'border'}
                    onClick={() => {
                        createLog('event', 'asset_table_edit_avg_entry_price_close_modal')
                        modalRef.current.close()
                    }}
                />
                <WizButton
                    text={t('modal.changeAvgBuyPrice.confirm')}
                    buttonType={'primary'}
                    disabled={!price}
                    onClick={async () => {
                        modalRef.current.close()
                        await apiEditAvgEntryPrice()
                        createLog('event', 'asset_table_edit_avg_entry_price', {
                            key_id: keyId,
                            symbol: symbol,
                            avg_entry_price: price?.toString(),
                        })
                        refreshCallback && refreshCallback()
                    }}
                />
            </div>
        </WizContentModal>
    )
}

export default React.forwardRef(EditAvgBuyPriceModal)

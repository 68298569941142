import {IKeySummary} from '@type/profile'
import React, {useEffect, useMemo, useRef} from 'react'
import useQueryGetExchanges from '@hook/query/core/exchange/useQueryGetExchanges'
import {useTranslation} from 'next-i18next'
import useFormatDate from '@hook/useFormatDate'
import useFormatNumber from '@hook/useFormatNumber'
import usePageTipHandler from '@component/layouts/PageConfig/hooks/usePageTipHandler'
import useQueryFetchMe from '@hook/query/account/useQueryFetchMe'
import useQueryGetKeyGraph from '@hook/query/portfolio/useQueryGetKeyGraph'
import BigNumber from 'bignumber.js'
import {isEmptyArray} from '@util/array'
import useView from '@hook/useView'
import WizImage from '@component/images/WizImage'
import WizTipAutoOpen from '@component/tooltip/WizTipAutoOpen'
import Text from '@component/Text/Text'
import Span from '@component/Text/Span'
import BotStatusTag from '@component/tags/BotStatusTag'
import IconBadgeCopier from '@svg/copytrading/ic_badge_copy.svg'
import IconBadgeLeader from '@svg/copytrading/ic_badge_leader.svg'
import {getNumberColorClass} from '@util/etc'
import AssetTableHeaderKeyGraph from '@feature/portfolio/components/charts/AssetTableHeaderKeyGraph/AssetTableHeaderKeyGraph'
import {GraphPeriodOption} from '@hook/useChartPeriod'
import IconUser from '@svg/common/ic_user.svg'

interface IPropsHeader extends IKeySummary {
    isHide: boolean
    userId?: string
    isMe?: boolean
}

const TEN_MINUTES = 10 * 60 * 1000
export const ASSETTABLE_LATEST_KEY = 'assetTable_latest_key'
const AssetTableHeader: React.FC<IPropsHeader> = item => {
    const latestKeyTooltipRef = useRef(null)
    const {getExchange} = useQueryGetExchanges()
    const {t} = useTranslation()
    const {displayDate} = useFormatDate()
    const {displayPercent, displayBalance} = useFormatNumber()
    const {getTip} = usePageTipHandler()

    const {data: me} = useQueryFetchMe(undefined, false)

    const {exchange, key_name, start_at, total_value, key_id, copier_count, max_copier, isHide, isMe} = item

    const {graph: keyGraph} = useQueryGetKeyGraph(key_id, GraphPeriodOption.Month, !isHide)

    const totalPnl = useMemo(() => item?.assets?.reduce((a, b) => a + b.pnl, 0), [item?.assets])
    const totalRealizedPnl = useMemo(
        () => item?.assets?.filter(item => item?.is_margin === false)?.reduce((a, b) => a + b.pnl, 0),
        [item?.assets],
    )
    const totalProfitRate = useMemo(() => {
        if (!totalPnl || !total_value) return 0
        return new BigNumber(totalPnl).times(100).div(new BigNumber(total_value).minus(totalRealizedPnl)).toNumber()
    }, [totalPnl, total_value, totalRealizedPnl])

    const isLatestRegistered = useMemo(() => {
        if (isEmptyArray(me?.keys)) return false
        const sortedByCreatedAtList = me?.keys.sort((a, b) => b.created_at - a.created_at) ?? []
        const isSameKey = sortedByCreatedAtList?.[0].key_id === key_id
        if (isSameKey) {
            const now = new Date().getTime()
            const createdTime = now - sortedByCreatedAtList?.[0].created_at * 1000

            return createdTime < TEN_MINUTES
        }
        return false
    }, [me?.keys, key_id])

    const {renderBlur} = useView()

    useEffect(() => {
        if (isLatestRegistered) {
            if (getTip(ASSETTABLE_LATEST_KEY) === 'true') return
            latestKeyTooltipRef.current.scrollIntoView()
        }
    }, [isLatestRegistered, getTip])

    return (
        <div
            className={
                'px-[20px] py-[10px] bg-primary_tint02 dark:bg-dark_primary_tint02 rounded-tl-[5px] rounded-tr-[5px] flex justify-between items-center'
            }>
            <div className={'flex items-center'}>
                <WizImage
                    src={getExchange(exchange)?.icon}
                    width={30}
                    height={30}
                    containerClassName={'sm:hidden mr-[10px]'}
                />
                <div className={''}>
                    <div className={'flex items-center'}>
                        <div>
                            {isLatestRegistered ? (
                                <div ref={latestKeyTooltipRef} className={'inline-block'}>
                                    <WizTipAutoOpen
                                        type={ASSETTABLE_LATEST_KEY}
                                        placement={'top'}
                                        title={t('table.asset.header.tooltip.latest.key.title')}
                                        onClick={e => e.stopPropagation()}
                                        render={
                                            <Text className={'text-ti1 text-gray01 dark:text-dark_gray01'}>
                                                {getExchange(exchange)?.display_name}
                                            </Text>
                                        }
                                    />
                                </div>
                            ) : (
                                <Span className={'text-ti1 text-gray01 dark:text-dark_gray01'}>
                                    {getExchange(exchange)?.display_name}
                                </Span>
                            )}

                            <Span className={'text-ti1 text-gray02 dark:text-dark_gray02 ml-[5px] sm:hidden '}>
                                {key_name}
                            </Span>
                        </div>
                        {item.is_copier && isMe && (
                            <>
                                <WizTipAutoOpen
                                    type={'assetTable_copier'}
                                    className={''}
                                    title={t('table.asset.header.tooltip.copier.title')}
                                    contents={t('table.asset.header.tooltip.copier.content')}
                                    onClick={e => e.stopPropagation()}
                                    render={<IconBadgeCopier className={'ml-[5px] mr-[10px]'} />}
                                />
                                <BotStatusTag status={item.copier_status} className={'sm:hidden'} />
                            </>
                        )}
                        {item.is_leader && isMe && (
                            <>
                                <WizTipAutoOpen
                                    type={'assetTable_leader'}
                                    className={''}
                                    title={t('table.asset.header.tooltip.leader.title')}
                                    contents={t('table.asset.header.tooltip.leader.content')}
                                    onClick={e => e.stopPropagation()}
                                    render={<IconBadgeLeader className={'ml-[5px] mr-[10px]'} />}
                                />
                                <div className={'flex items-center gap-x-[5px] sm:hidden'}>
                                    <IconUser className={'w-[14px] fill-gray01 dark:fill-dark_gray01'} />
                                    <Text className={'text-ti2 text-gray02 dark:text-dark_gray02'}>
                                        {copier_count ?? 0}/{max_copier ?? 0}
                                    </Text>
                                </div>
                            </>
                        )}
                    </div>

                    <Text className={'text-body_comm text-gray03 dark:text-dark_gray03'}>
                        {t('table.asset.header.start', {
                            date: displayDate(start_at ?? 0, t('dateFormat.date')),
                        })}
                    </Text>
                </div>
            </div>
            <div
                className={
                    'flex-1 grid grid-cols-[1fr_170px_130px] md:grid-cols-[1fr_170px] sm:block justify-end items-center'
                }>
                <div className={'sm:hidden'}>
                    <Text className={'text-body_comm text-gray02 dark:text-dark_gray02 text-right'}>
                        {t('table.asset.header.profit.title')}
                    </Text>
                    <Text
                        className={`text-ti2 ${getNumberColorClass(totalPnl)} text-right ${renderBlur(
                            isHide,
                        )}`}>{`${displayBalance(totalPnl, {
                        showPreSign: true,
                    })} (${displayPercent(totalProfitRate)})`}</Text>
                </div>
                <div>
                    <Text className={'text-body_comm text-gray02 dark:text-dark_gray02 text-right'}>
                        {t('table.asset.header.totalValue.title')}
                    </Text>
                    <Text className={`text-ti2 text-gray01 dark:text-dark_gray01 text-right`}>{`${displayBalance(
                        total_value,
                        {
                            showPreSign: true,
                            isApproximately: isHide,
                        },
                    )}`}</Text>
                </div>
                <AssetTableHeaderKeyGraph
                    input={keyGraph}
                    width={70}
                    height={36}
                    className={'md:hidden flex justify-end'}
                />
            </div>
        </div>
    )
}

export default React.memo(AssetTableHeader)

import React from 'react'
import Text from '@component/Text/Text'
import {useTranslation} from 'next-i18next'
import Link from 'next/link'
import useQueryFetchMe from '@hook/query/account/useQueryFetchMe'
import {ProIpStatus} from '@type/profile'
import {createLog} from '@util/logs'
import {getLinkUrl, URL_ACCOUNT_PRO} from '@constant/url'

interface IProps {
    className?: string
}

const CheckUseProComponent: React.FC<IProps> = ({className}) => {
    const {t} = useTranslation()

    const {data: me} = useQueryFetchMe(undefined, false)
    const isNotSetProIP = me?.keys?.some(key => key.pro_ip_status === ProIpStatus.Set)

    if (!me?.is_pro) return null

    return (
        <div className={`flex items-center gap-x-[7px] ${className}`}>
            <div className={'relative'}>
                <div
                    className={
                        'w-[8px] h-[8px] rounded-full bg-gradient_pro opacity-30 animate-[waveCircle_1.25s_ease-in_infinite]'
                    }></div>
                <div
                    className={
                        'w-[8px] h-[8px] rounded-full bg-gradient_pro absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]'
                    }></div>
            </div>
            <div>
                {isNotSetProIP ? (
                    <Text className={'bg-gradient_pro text-transparent bg-clip-text text-ti2'}>
                        {t('portfolio.table.usePro.text')}
                    </Text>
                ) : (
                    <Link
                        href={getLinkUrl(URL_ACCOUNT_PRO)}
                        onClick={() => createLog('event', 'portfolio_click_pro_badge')}
                        className={'relative'}>
                        <Text className={'bg-gradient_pro text-transparent bg-clip-text text-ti2 underline'}>
                            {t('portfolio.table.not.usePro.text')}
                        </Text>
                        <div className={'h-[1px] w-full absolute bottom-[2px] bg-gradient_pro'} />
                    </Link>
                )}
            </div>
        </div>
    )
}

export default React.memo(CheckUseProComponent)
